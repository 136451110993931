// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Hook Imports
import { useAuth } from 'src/hooks/useAuth'
import axiosInstance from 'src/services/serverAxios'
import toast from 'react-hot-toast'
import { useMediaQuery, useTheme } from '@mui/material'

/**
 *  Set Home URL
 */
const getHomeRoute = (isMobile: boolean) => {
  return isMobile ? '/home-page' : '/dashboards'
}

const Home = () => {
  // Vars for breakpoint mobile
  const theme = useTheme()
  const smDownBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  // ** Hooks
  const auth = useAuth()
  const router = useRouter()

  // ** Catch 401 errors
  axiosInstance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (401 === error.response.status) {
        error.message = 'Please sign in to continue.'
        toast.error(error.message)
        auth.logout()
      }

      return Promise.reject(error)
    }
  )

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (auth.user) {
      const homeRoute = getHomeRoute(smDownBreakpoint)

      // Redirect user to Home URL
      router.replace(homeRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, smDownBreakpoint, auth.user])

  return <Spinner />
}

export default Home
